/* src/App.css */
.header {
  text-align: center;
  background-color: #000000; /* Black */
  padding: 20px;
  color: #ffffff; /* White */
}

.iframe-container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  background-color: #f5f5f5; /* Off-White */
  padding: 20px; /* Add padding to the main body */
  border-radius: 10px;
}

iframe {
  width: 100%;
  height: 500px;
  border: 2px solid #e0e0e0; /* Light Grey */
  border-radius: 10px;
}

.footer {
  background-color: #282c34;
  text-align: center;
  margin-top: 20px;
  
}

.cta-box {
  background-color: #f5f5f5; /* Off-White */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-box h2 {
  margin-bottom: 10px;
  color: #333333; /* Dark Grey */
}

.cta-box p {
  margin-bottom: 20px;
  color: #555555; /* Medium Grey */
}

.cta-box a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Blue */
  color: #ffffff; /* White */
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-box a:hover {
  background-color: #0056b3; /* Darker Blue */
}

.landing-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5; /* Off-White */
}

.landing-page canvas {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landing-page button {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #000000; /* Black */
  color: #ffffff; /* White */
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.landing-page button:hover {
  background-color: #333333; /* Darker Black */
}
